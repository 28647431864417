/* src/Section2.css */
.section2 {
    padding: 0px 3% 100px 3%;
    color: #fff;
    background-color: #00AFE1; 
  }
  


  .tokenomics {
    text-align: center;
    padding: 20px;
    color: white;
    overflow-x: hidden;
  }
  
  .tokenomics h2 {
    font-size: 3em;
    margin-bottom: 50px;
  }
  
  .tokenomics-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    flex-wrap: wrap;
  }
  
  .coins, .lost {
    max-width: 200px;
    margin: 10px;
  }
  
  .tokenomics-info {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
    flex-wrap: wrap;
    max-width: 80%;
  }
  
  .info-box {
    background-color: transparent;
    border: 4px solid white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.8em;
    flex: 1 1 calc(33.333% - 20px);
    margin: 10px;
    box-sizing: border-box;
  }
  
  .contract-address {
    background-color: transparent;
    border: 4px solid white;
    padding: 10px;
    border-radius: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 auto;
    max-width: 80%;
  }
  
  .contract-address p:first-child {
    font-size: 2.2em;
    margin-bottom: 5px;
  }
  
  .contract-address p:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  @media (max-width: 768px) {
    .tokenomics-content {
      flex-direction: column;
    }
  
    .tokenomics-info {
      flex-direction: column;
    }
  
    .info-box {
      flex: 1 1 100%;
    }
  
    .coins, .lost {
      max-width: 150px;
    }
  
    .tokenomics h2 {
      font-size: 2.5em;
    }
  
    .contract-address p:first-child {
      font-size: 1.8em;
    }
  }
  
  @media (max-width: 480px) {
    .coins, .lost {
      max-width: 100px;
    }
  
    .tokenomics h2 {
      font-size: 2em;
    }
  
    .contract-address p:first-child {
      font-size: 1.5em;
    }
  }
  

  .logoTokenomics{
    max-width: 40%;
  }