.custom-navbar {
    background-color: #00AFE1 !important;
    padding: 0 3%;
  }
  
  .custom-navbar .navbar-brand, .custom-navbar .navbar-nav .nav-link {
    color: #ffffff !important;
    margin-right: 50px; 
    text-decoration: none !important;
    font-size: 22px;
  }
  

  .custom-navbar .navbar-nav .nav-link:last-child {
    margin-right: 0;
  }
  
  .custom-navbar .btn-primary {
    background-color: #0255FB;
    border: none;
    padding: 10px 30px !important;
  }
  
  
  @media (max-width: 991px) {
    .custom-navbar .navbar-collapse {
      justify-content: flex-end;
      height: 100vh;
    }

    .custom-navbar .navbar-brand, .custom-navbar .navbar-nav .nav-link{
        margin: 0;
      }

      .custom-navbar .navbar-nav {
        width: 100%;
        justify-content: center; 
      }
    
      .custom-navbar .navbar-nav .nav-link {
        margin: 5px 0; 
      }
  
      .custom-navbar .btn-primary {
        width: 40%; 
        margin-top: 20px; 
        align-self: center; 
      }
  }
  