/* src/Footer.css */
.footer {
    background-color: #00AFE1;
    color: #ffffff;
    padding: 40px 0;
  }

  a {
    color: inherit !important;
    text-decoration: none !important;
  }
  
  a:hover {
    text-decoration: underline !important;
  }
  
  
  .footer h5 {
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .footer p {
    color: #eef4fa;
    font-size: 14px;
  }
  
  .footer ul {
    list-style: none;
    padding: 0;
  }


  
  .footer ul li {
    margin-bottom: 10px;
    color: #cee6ff;
  }
  
  .footer .social-icons {
    font-size: 24px;
    color: #ffffff;
  }

  .social-icons img{
    width:30px;
    margin-right: 10px;
  }
  
  .footer .social-icons .fa {
    margin-right: 10px;
  }
  
  .footer .social-icons .fa:hover {
    color: #1e90ff;
  }
  
  .footer-bottom p {
    color: #cee6ff;
    font-size: 14px;
    margin-top: 20px;
  }
  
  @media (max-width: 767px) {
    .footer {
      text-align: center;
    }
  
    .footer .social-icons {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  
